import {Kebab32} from "@bphxd/ds-core-react/lib/icons";
import {createColumnHelper} from "@tanstack/react-table";
import {COPRO_US_BATCHES_DETAILS_API} from "graphql/coprocessing/batches";
import {COPRO_US_SHIPMENTS_API_GET_SHIPMENTS} from "graphql/coprocessing/shipments";
import {mapValues} from "lodash";
import StatusBadge from "modules/co-processing/components/Shared/BatchesStatusChip";
import {BATCHES_LABELS} from "modules/co-processing/constants/batches";
import {PAGE_TITLE} from "modules/co-processing/constants/coProcessing";
import {shipmentTypeToUpperCase} from "../CreateShipment/formConfigs";
import HistoricalDropdown from "./HistoricalDropdown";
import {format} from "./utils";

/**
 * @param {Object} details - Object containing details of a batch related to PI data fields to be transformed
 * @returns {Object} The transformed details object with numeric values formatted according to US locale.
 */
function transformDetails(details) {
  const {__typename, ...piData} = details ?? {};
  return mapValues(piData, (v) => format.number(v));
}

function transformHistoricalBatches(batch) {
  return {
    batch_id: batch?.batch_id,
    batch_start_time: format.date(batch?.batch_start_time),
    batch_end_time: format.date(batch?.batch_end_time),
    tank_certified_datez: format.date(batch?.tank_certified_datez),
    c14_test_date: format.date(batch?.c14_test_date),
    operating_condition: format.default(batch?.operating_condition),
    tank_number: format.default(batch?.tank_number),
    submitted: null,
    tank_volume: format.number(batch?.tank_volume),
    c14_pmc: format.default(batch?.c14_pmc),
    rin_per_gal: batch?.rin_per_gal ?? 1.7, // Populate column with default value of 1.7
    ...transformDetails(batch?.details),
  };
}

function transformHistoricalShipments({parts, ...shipment}) {
  return parts?.map((part) => ({
    status: format.default(shipment.shipment_status || part?.shipment_status),
    batch_id: format.default(part?.batch_id),
    shipment_id: format.default(part?.allocated_shipment_id),
    shipment_type: format.default(
      shipment.shipment_type,
      shipmentTypeToUpperCase,
    ),
    notes: format.default(part?.notes),
    destination: format.default(shipment?.destination),
    sub_type: format.default(part?.sub_type),
    shipment_date: format.date(shipment?.shipment_date),
    load_start_time: format.date(part?.shipment_start_date),
    load_end_time: format.date(part?.shipment_end_date),
    credits_qualified: format.default(part?.credits_qualified),
    renewable_shipment_flag: format.bool(part?.renewable_shipment_flag),
    actual_volume: format.number(part?.total_actual_volume),
  }));
}

const batchesColumnDefs = [
  {
    accessorKey: "batch_id",
    header: "Batch ID",
  },
  {
    accessorKey: "batch_start_time",
    header: "#3DHDS Start time",
    meta: {type: "date"},
  },
  {
    accessorKey: "batch_end_time",
    header: "#3DHDS End time",
    meta: {type: "date"},
  },
  {
    accessorKey: "tank_certified_datez",
    header: "Tank certified date/time",
    meta: {type: "date"},
  },
  {
    accessorKey: "c14_test_date",
    header: "C14 Test date",
    meta: {type: "date"},
  },
  {
    accessorKey: "operating_condition",
    header: "Operating condition",
  },
  {
    accessorKey: "tank_number",
    header: "Tank number",
  },
  {
    accessorKey: "tank_volume",
    header: "Tank volume at certification",
    meta: {type: "number"},
  },
  {
    accessorKey: "rin_per_gal",
    header: "RIN/gal",
    size: 80,
  },
  {
    accessorKey: "c14_pmc",
    header: BATCHES_LABELS.PERCENT_CARBON,
    meta: {type: "number"},
  },
  {
    accessorKey: "submitted",
    header: "Submitted",
  },

  // Pi data fields
  {
    accessorKey: "renewable_feed_bbl",
    header: "Renewable feed (bbl)",
    meta: {type: "number"},
  },
  {
    accessorKey: "cold_recirc_in_renewable_feed_bbl",
    header: "Cold recirc in renewable feed (bbl)",
    meta: {type: "number"},
  },
  {
    accessorKey: "renewable_feed_tank_col_su_sd_bbl",
    header:
      "Renewable feed tank vol adj to cold recirc in renewable feed for SU/SD (bbl)",
    size: 200,
    meta: {type: "number"},
  },
  {
    accessorKey: "total_feed_bbl",
    header: "#3DHDS Feed - Total feed (bbl)",
    meta: {type: "number"},
  },
  {
    accessorKey: "unit_rundown_bbl",
    header: "#3DHDS Production - Unit Rundown (bbl)",
    meta: {type: "number"},
  },
  {
    accessorKey: "hot_diesel_recirc_bbl",
    header: "#3DHDS Production - Hot Diesel Recirc (bbl)",
    meta: {type: "number"},
  },
  {
    accessorKey: "cold_recirc_bbl",
    header: "#3DHDS Production - Cold recirc (bbl)",
    meta: {type: "number"},
  },
  {
    accessorKey: "renewable_feed_lb",
    header: "#3DHDS Feed - Renewable Feed (lb)",
    meta: {type: "number"},
  },
  {
    accessorKey: "cold_recirc_in_renewable_feed_lb",
    header: "#3DHDS Feed - Cold Recirc in Renewable Feed (lb)",
    meta: {type: "number"},
  },
  {
    accessorKey: "renewable_feed_tank_col_su_sd_lb",
    header:
      "Renewable feed tank vol adj to cold recirc in renewable feed for SU/SD (lb)",
    meta: {type: "number"},
    size: 200,
  },
  {
    accessorKey: "total_feed_lb",
    header: "Total feed (lb)",
    meta: {type: "number"},
  },
  {
    accessorKey: "unit_rundown_lb",
    header: "#3DHDS Production - Unit Rundown (lb)",
    meta: {type: "number"},
  },
  {
    accessorKey: "hot_diesel_recirc_lb",
    header: "#3DHDS Production - Hot Diesel Recirc (lb)",
    meta: {type: "number"},
  },
  {
    accessorKey: "cold_recirc_lb",
    header: "#3DHDS Production - Cold recirc (lb)",
    meta: {type: "number"},
  },
  {
    accessorKey: "rundown_density_lb_kgal",
    header: "Rundown density (lb/kgal)",
    meta: {type: "number"},
  },
];

const shipmentColumDefs = [
  {
    accessorKey: "status",
    header: "Status",
    customCell: (value) => (
      <StatusBadge
        style={{justifyContent: "center"}}
        value={value?.replace(" ", "_")}
      />
    ),
  },
  {
    accessorKey: "batch_id",
    header: "Batch ID",
  },
  {
    accessorKey: "shipment_date",
    header: "Shipment date",
    meta: {type: "date"},
  },
  {
    accessorKey: "shipment_type",
    header: "Shipment type",
  },
  {
    accessorKey: "shipment_id",
    header: "Shipment ID",
  },
  {
    accessorKey: "actual_volume",
    header: "Total actual shipment volume",
    meta: {type: "number"},
  },
  {
    accessorKey: "credits_qualified",
    header: "RINS qualified",
    meta: {type: "bool"},
  },
  {
    accessorKey: "load_start_time",
    header: "Load start time",
    meta: {type: "date"},
  },
  {
    accessorKey: "load_end_time",
    header: "Load end time",
    meta: {type: "date"},
  },
  {
    accessorKey: "notes",
    header: "Notes",
    meta: {
      style: {maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis"},
    },
  },
  {
    accessorKey: "renewable_shipment_flag",
    header: "Renewable",
    meta: {type: "bool"},
  },
  {
    accessorKey: "sub_type",
    header: "Sub type",
  },
  {
    accessorKey: "action_menu",
    header: (
      <div className="sticky">
        <Kebab32 />
      </div>
    ),
    dropdownCell: (cell) => {
      return <HistoricalDropdown shipment={cell?.row?.original} />;
    },
    enableSorting: false,
    size: 50,
    meta: {
      hideHeader: true,
    },
  },
];

const columnHelper = createColumnHelper();

const getRenderCell = (dropdownCell, customCell) => (cell) => {
  if (dropdownCell) {
    return dropdownCell(cell);
  }
  if (customCell) {
    return customCell(cell.getValue());
  }
  return cell.getValue();
};

const generateColumns = (columnConfig) =>
  columnConfig.map(
    ({accessorKey, header, customCell, dropdownCell, ...props}) => {
      const renderCell = getRenderCell(dropdownCell, customCell);

      return columnHelper.accessor(accessorKey, {
        header,
        cell: renderCell,
        ...props,
      });
    },
  );

const getHistoricalConfig = (type) => {
  const configs = {
    batches: {
      pageTitle: `${PAGE_TITLE.HISTORICAL} ${PAGE_TITLE.BATCHES}`,
      apiQuery: COPRO_US_BATCHES_DETAILS_API,
      getColumns: () => generateColumns(batchesColumnDefs),
      transformFn: (data) => {
        const batches = data?.bioLcCoproUsBatchesApi?.body?.batches;
        return batches.length ? batches.map(transformHistoricalBatches) : [];
      },
    },
    shipments: {
      pageTitle: `${PAGE_TITLE.HISTORICAL} ${PAGE_TITLE.SHIPMENTS}`,
      apiQuery: COPRO_US_SHIPMENTS_API_GET_SHIPMENTS,
      getColumns: () => generateColumns(shipmentColumDefs),
      transformFn: (data) => {
        const shipments = data?.bioLcCoproUsShipmentsApi?.body?.shipments;
        return shipments.length
          ? shipments.flatMap(transformHistoricalShipments)
          : [];
      },
    },
  };

  return configs[type];
};

export {
  batchesColumnDefs,
  getHistoricalConfig,
  shipmentColumDefs,
  transformHistoricalBatches,
  transformHistoricalShipments,
};
