import moment from "moment";
import {DDV, TDV} from "../components/view-document/utils";
import formatDate from "../utils";

const isAfter5Oct2015 = (date) => {
  if (date) {
    return moment(date, "YYYY-MM-DD").isAfter("2015-10-05");
  }
  return "";
};

export const defaultValues = {
  additionalInfo: "",
  additionalNotes: "",
  agriculturalBiomassIntermediateCropFlag: null,
  agriculturalBiomassLowRiskFlag: null,
  availableBioProductTons: "",
  productEnergyContent: "",
  certificateNumber: "",
  certificationSystem: "",
  chainOfCustodyOption: "",
  contractNumber: "",
  customerRef: "",
  dealNotes: "",
  deliveryNotes: "",
  density: "",
  densityUom: "",
  differenceVol: "",
  euRedCompliantFlag: null,
  wasteOrAnimalByProductPermitNumber: "",
  supportForFuelProductionOrFuelPrecursorReceivedFlag: null,
  ifYesSpecifySupportNatureAndScheme: "",
  expiryDate: "",
  feedstockCountryCode: "",
  dateOfIssuance: "",
  feedstockMonth: "",
  feedstockQty: "",
  feedstockQtyUom: "",
  feedstockQuarter: "",
  fossilNotes: "",
  ghgEccr: 0,
  ghgEccs: 0,
  ghgEec: 0,
  ghgEee: 0,
  ghgEl: 0,
  ghgEmissionIn: "",
  ghgEmissionOut: "",
  ghgEmissionPercentageIn: "",
  ghgEmissionPercentageOut: "",
  ghgEp: 0,
  ghgEsca: 0,
  ghgEtd: 0,
  ghgEu: 0,
  ghgTotal: 0,
  importedInvoiceVol: "",
  invoiceProviderName: "",
  isccCompliantFlag: null,
  materialDispatchDatez: "",
  materialSustainabilityCriteriaFlag: null,
  mbLocation: "",
  motDistanceKm: "",
  motFromRefinery: "",
  motToRefinery: "",
  observationNotes: "",
  operationDesc: "",
  orderNumber: "",
  originDestinationDesc: "",
  physicalReceiptDate: "",
  processedFeedstockTons: "",
  processType: "",
  productConcludedQty: "",
  productInvoiceVol: "",
  productionLossTons: "",
  productNetVol: "",
  productType: "",
  rawExpiryDate: "",
  rawIssueDate: "",
  rawMaterialCultivationCountryName: "",
  rawMaterialDeliveryDate: "",
  rawMaterialOriginCountryName: "",
  rawMaterialType: "",
  rawMaterialWasteOrResidueFlag: null,
  recipientAddress: "",
  recipientName: "",
  recipientReceiptAddress: "",
  recipientReceiptAddressSameFlag: null,
  refineryDischargeVol: "",
  refineryLoadedVol: "",
  sdNumber: "",
  supplierAddress: "",
  supplierDispatchAddress: "",
  supplierDispatchAddressSameFlag: null,
  supplierInvoiceVol: "",
  supplierName: "",
  thirdPartyName: "",
  transportationEmissionId: "",
  transportationEmissionRoute: "",
  transportLossTons: "",
  vesselAtHuelva: "",
  vesselAtTespa: "",
  emissionForTransport: false,
  defaultValueAppliedFlag: null,
  bioProductUser: null,
  bioProductProducer: null,
  userInstallationDate: "",
  producerInstallationDate: "",
  userInstallationDateFlag: null,
  producerInstallationDateFlag: null,
  disaggregatedDefaultValueOilFlag: null,
  disaggregatedDefaultValueSoiln2oFlag: null,
  bonusCo245gManureUsedFlag: null,
  bonusCo229gManureUsedFlag: null,
  supplychainIncentiveReceivedFlag: null,
  supplychainIncentiveReceivedName: "",
  nuts2Region: "",
  splitDetails: [],
};

export const getProps = (
  documentData,
  dateFormat,
  decimalFormat,
  formatNumber,
) => {
  const {
    additionalInfo = "",
    additionalNotes = "",
    agriculturalBiomassIntermediateCropFlag = null,
    agriculturalBiomassLowRiskFlag = null,
    availableBioProductTons = "",
    productEnergyContent = "",
    certificateNumber = "",
    certificationSystem = "",
    contractNumber = "",
    customerRef = "",
    dealNotes = "",
    deliveryNotes = "",
    density = "",
    densityUom = "",
    differenceVol = "",
    euRedCompliantFlag = null,
    wasteOrAnimalByProductPermitNumber = "",
    supportForFuelProductionOrFuelPrecursorReceivedFlag = null,
    ifYesSpecifySupportNatureAndScheme = "",
    expiryDate = "",
    feedstockCountryCode = "",
    dateOfIssuance = "",
    feedstockMonth = "",
    feedstockQty = "",
    feedstockQtyUom = "",
    feedstockQuarter = "",
    fossilNotes = "",
    ghgEccr = 0,
    ghgEccs = 0,
    ghgEec = 0,
    ghgEee = 0,
    ghgEl = 0,
    ghgEmissionIn = "",
    ghgEmissionOut = "",
    ghgEmissionPercentageIn = "",
    ghgEmissionPercentageOut = "",
    ghgEp = 0,
    ghgEsca = 0,
    ghgEtd = 0,
    ghgEu = 0,
    ghgTotal = 0,
    importedInvoiceVol = "",
    invoiceProviderName = "",
    isccCompliantFlag = null,
    materialDispatchDatez = "",
    materialSustainabilityCriteriaFlag = null,
    mbLocation = "",
    motDistanceKm = "",
    motFromRefinery = "",
    motToRefinery = "",
    observationNotes = "",
    operationDesc = "",
    orderNumber = "",
    originDestinationDesc = "",
    physicalReceiptDate = "",
    processedFeedstockTons = "",
    processType = "",
    productConcludedQty = "",
    productInvoiceVol = "",
    productionLossTons = "",
    productNetVol = "",
    productType = "",
    rawExpiryDate = "",
    rawIssueDate = "",
    rawMaterialCultivationCountryName = "",
    rawMaterialDeliveryDate = "",
    rawMaterialOriginCountryName = "",
    rawMaterialType = "",
    rawMaterialWasteOrResidueFlag = null,
    recipientAddress = "",
    recipientName = "",
    recipientReceiptAddress = "",
    recipientReceiptAddressSameFlag = null,
    refineryDischargeVol = "",
    refineryLoadedVol = "",
    sdNumber = "",
    supplierAddress = "",
    supplierDispatchAddress = "",
    supplierDispatchAddressSameFlag = null,
    supplierInvoiceVol = "",
    supplierName = "",
    thirdPartyName = "",
    transportationEmissionId = "",
    transportationEmissionRoute = "",
    transportLossTons = "",
    vesselAtHuelva = "",
    vesselAtTespa = "",
    defaultValueAppliedFlag = null,
    bioProductUser = null,
    bioProductProducer = null,
    userInstallationDate = "",
    producerInstallationDate = "",
    disaggregatedDefaultValueOilFlag = null,
    disaggregatedDefaultValueSoiln2oFlag = null,
    bonusCo245gManureUsedFlag = null,
    bonusCo229gManureUsedFlag = null,
    supplychainIncentiveReceivedFlag = null,
    supplychainIncentiveReceivedName = "",
    nuts2Region = "",

    splitDetails = [],
    bvAuditCreatedDatez = "",
    bvAuditChangedDatez = "",
    mbBalanceGroupId = "",
    mbLocationGroupId = "",
    previousVersionSdNumber = "",
    previousVersionId = "",
    productQty = "",
    productQtyUom = "",
  } = documentData;
  const props = {
    sdNumber,
    mbBalanceGroupId,
    mbLocationGroupId,
    bvAuditCreatedDatez,
    bvAuditChangedDatez,
    feedstockCountryCode,
    rawIssueDate,
    dateOfIssuance:
      dateOfIssuance == null || dateOfIssuance === ""
        ? ""
        : formatDate(dateOfIssuance, dateFormat),
    feedstockMonth,
    feedstockQuarter,
    feedstockQty:
      feedstockQty == null || feedstockQty === ""
        ? ""
        : formatNumber(parseFloat(feedstockQty), decimalFormat, 0),
    feedstockQtyUom,
    density,
    densityUom,
    supplierName,
    supplierAddress,
    supplierDispatchAddress,
    supplierDispatchAddressSameFlag,
    recipientName,
    recipientAddress,
    recipientReceiptAddress,
    recipientReceiptAddressSameFlag,
    certificationSystem,
    certificateNumber,
    materialDispatchDatez:
      materialDispatchDatez == null || materialDispatchDatez === ""
        ? ""
        : formatDate(materialDispatchDatez, dateFormat),
    rawExpiryDate,
    expiryDate,
    contractNumber,
    orderNumber,
    customerRef,
    transportLossTons,
    productNetVol,
    productInvoiceVol,
    refineryLoadedVol,
    refineryDischargeVol,
    differenceVol,
    invoiceProviderName,
    supplierInvoiceVol,
    importedInvoiceVol,
    observationNotes,
    processedFeedstockTons,
    productConcludedQty,
    availableBioProductTons,
    productEnergyContent:
      productEnergyContent == null || productEnergyContent === ""
        ? ""
        : formatNumber(parseFloat(productEnergyContent), decimalFormat, 0),
    productionLossTons,
    motFromRefinery,
    motDistanceKm,
    motToRefinery,
    originDestinationDesc,
    thirdPartyName,
    operationDesc,
    rawMaterialType,
    rawMaterialCultivationCountryName,
    physicalReceiptDate:
      physicalReceiptDate == null || physicalReceiptDate === ""
        ? ""
        : formatDate(physicalReceiptDate, dateFormat),
    mbLocation,
    ghgEec: formatNumber(parseFloat(ghgEec ?? "0"), decimalFormat, 0),
    ghgEl: formatNumber(parseFloat(ghgEl ?? "0"), decimalFormat, 0),

    ghgEp: formatNumber(parseFloat(ghgEp ?? "0"), decimalFormat, 0),
    ghgEtd:
      ghgEtd === DDV
        ? ghgEtd
        : formatNumber(parseFloat(ghgEtd ?? "0"), decimalFormat, 0),
    ghgEu: formatNumber(parseFloat(ghgEu ?? "0"), decimalFormat, 0),
    ghgEsca: formatNumber(parseFloat(ghgEsca ?? "0"), decimalFormat, 0),
    ghgEccs: formatNumber(parseFloat(ghgEccs ?? "0"), decimalFormat, 0),
    ghgEccr: formatNumber(parseFloat(ghgEccr ?? "0"), decimalFormat, 0),
    ghgEee: formatNumber(parseFloat(ghgEee ?? "0"), decimalFormat, 0),
    ghgTotal:
      ghgTotal === TDV || ghgTotal === DDV
        ? ghgTotal
        : formatNumber(parseFloat(ghgTotal ?? "0"), decimalFormat, 0),
    ghgEmissionIn,
    ghgEmissionPercentageIn:
      ghgEmissionPercentageIn === TDV || ghgEmissionPercentageIn === DDV
        ? ghgEmissionPercentageIn
        : formatNumber(
            parseFloat(ghgEmissionPercentageIn ?? "0"),
            decimalFormat,
            0,
          ),
    ghgEmissionOut,
    ghgEmissionPercentageOut,
    productType,
    processType,
    rawMaterialOriginCountryName,
    rawMaterialDeliveryDate,
    chainOfCustodyOption: "Mass Balance",
    additionalInfo,
    additionalNotes,
    deliveryNotes,
    dealNotes,
    fossilNotes,
    vesselAtHuelva,
    vesselAtTespa,
    transportationEmissionId,
    transportationEmissionRoute,
    isccCompliantFlag: isccCompliantFlag == null ? "" : isccCompliantFlag,
    euRedCompliantFlag: euRedCompliantFlag == null ? "" : euRedCompliantFlag,
    wasteOrAnimalByProductPermitNumber,
    supportForFuelProductionOrFuelPrecursorReceivedFlag,
    ifYesSpecifySupportNatureAndScheme:
      ifYesSpecifySupportNatureAndScheme === null
        ? ""
        : ifYesSpecifySupportNatureAndScheme,
    materialSustainabilityCriteriaFlag,
    agriculturalBiomassIntermediateCropFlag,
    agriculturalBiomassLowRiskFlag,
    rawMaterialWasteOrResidueFlag,
    emissionForTransport:
      transportationEmissionRoute !== null &&
      transportationEmissionRoute !== "",
    defaultValueAppliedFlag,
    bioProductUser,
    bioProductProducer,
    userInstallationDateFlag: isAfter5Oct2015(userInstallationDate),
    producerInstallationDateFlag: isAfter5Oct2015(producerInstallationDate),
    producerInstallationDate:
      producerInstallationDate == null || producerInstallationDate === ""
        ? ""
        : formatDate(producerInstallationDate, dateFormat),
    userInstallationDate:
      userInstallationDate == null || userInstallationDate === ""
        ? ""
        : formatDate(userInstallationDate, dateFormat),
    disaggregatedDefaultValueOilFlag,
    disaggregatedDefaultValueSoiln2oFlag,
    bonusCo245gManureUsedFlag,
    bonusCo229gManureUsedFlag,
    supplychainIncentiveReceivedFlag,
    supplychainIncentiveReceivedName,
    nuts2Region,
    replacePreviousVersion: !!previousVersionId,
    previousVersionSdNumber,
    previousVersionId,
    productQty:
      productQty == null || productQty === ""
        ? ""
        : formatNumber(parseFloat(productQty), decimalFormat, 0),

    productQtyUom,
    splitDetails: splitDetails?.map((split) => ({
      physicalReceiptDate:
        split.physicalReceiptDate == null || split.physicalReceiptDate === ""
          ? ""
          : formatDate(split.physicalReceiptDate, dateFormat),
      ghgEec: formatNumber(parseFloat(split.ghgEec ?? "0"), decimalFormat, 0),
      ghgEl: formatNumber(parseFloat(split.ghgEl ?? "0"), decimalFormat, 0),
      ghgEp: formatNumber(parseFloat(split.ghgEp ?? "0"), decimalFormat, 0),
      ghgEtd:
        split.ghgEtd === DDV
          ? split.ghgEtd
          : formatNumber(parseFloat(split.ghgEtd ?? "0"), decimalFormat, 0),
      ghgEu: formatNumber(parseFloat(split.ghgEu ?? "0"), decimalFormat, 0),
      ghgEsca: formatNumber(parseFloat(split.ghgEsca ?? "0"), decimalFormat, 0),
      ghgEccs: formatNumber(parseFloat(split.ghgEccs ?? "0"), decimalFormat, 0),
      ghgEccr: formatNumber(parseFloat(split.ghgEccr ?? "0"), decimalFormat, 0),
      ghgEee: formatNumber(parseFloat(split.ghgEee ?? "0"), decimalFormat, 0),
      ghgTotal:
        split.ghgTotal === TDV || split.ghgTotal === DDV
          ? split.ghgTotal
          : formatNumber(parseFloat(split.ghgTotal ?? "0"), decimalFormat, 0),
      feedstockQty: formatNumber(
        parseFloat(split.feedstockQty ?? "0"),
        decimalFormat,
        0,
      ),
      originCountryName: split.originCountryName,
      transportationEmissionId: split.transportationEmissionId,
      transportationEmissionRoute: split.transportationEmissionRoute,
      defaultValueAppliedFlag: split.defaultValueAppliedFlag,
    })),
  };
  return props;
};

export default {defaultValues, getProps};
