import {DIV_CODE_COPRO, DIV_CODE_SAF} from "./divisionDetails";

export const documentStatus = {
  COMPLETED: "completed",
  READY_TO_ASSIGN: "ready_to_assign",
};

export const getFeatureNameByDivCode = (divisionCode) => {
  switch (divisionCode.toUpperCase()) {
    case DIV_CODE_COPRO:
      return "coProcessing";
    case DIV_CODE_SAF:
      return "saf";
    default:
      return "BioVerse";
  }
};

export default {};
