import {ReversalMutationAPI} from "graphql/MassBalance/Actions/reversalMutation";
import {mandateActionItems} from "modules/GlobalMandateCertificates/utils";
import PropTypes from "prop-types";
import {client} from "providers/Apollo";
import {useAppSetting} from "providers/appSetting";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {Button, Modal, ModalBody, ModalFooter, Spinner} from "reactstrap";
import {getDivisionData, getSiteDetails} from "utils/helpers/getAppSetting";

const MandateReversalModal = ({
  modal,
  setModal,
  rowData,
  divisionCode,
  currentFilterValues,
}) => {
  const {appSetting} = useAppSetting();
  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(divisionCode);

  const [showModal, setShowModal] = useState(false);
  const [eventData, setEventData] = useState();
  const [eventId, setEventId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const {headerText, bodyTextArr, actionButtonText} = eventData || {
    headerText: "",
    bodyTextArr: [],
    actionButtonText: "",
    eventName: "",
    eventType: "",
  };

  useEffect(() => {
    switch (modal) {
      case divisionCode + mandateActionItems.REMOVE_ALLOCATION:
        setShowModal(true);
        setEventData({
          headerText: "Remove allocation",
          bodyTextArr: [
            "Removing allocation will make quantity available again.",
            <>
              The generated document will remain available in outgoing documents
              with the status: <i>removed.</i>
            </>,
          ],
          actionButtonText: "Confirm removal",
          eventName: "mandate_allocation",
          eventType: "revert",
        });
        setEventId(rowData?.original?.outboundRecord?.allocationGroupId);
        break;
      default:
        setShowModal(false);
        setModal("");
        break;
    }
  }, [
    divisionCode,
    modal,
    rowData?.original?.outboundRecord?.allocationGroupId,
    setModal,
  ]);

  const onSubmit = async () => {
    setIsLoading(true);
    const {data} = await ReversalMutationAPI({
      event: {
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
        mbLocationGroupId: currentFilterValues?.mbLocationGroupId,
        mbBalanceGroupId: "",
        mbPeriodId: currentFilterValues?.periodId,
        eventName: eventData.eventName,
        eventType: eventData.eventType,
        eventId,
        recipientName: rowData?.original?.outboundRecord?.recipientName,
      },
    });

    const statusCode = data?.bioLcGlobalMassBalanceReversal?.statusCode;

    if (statusCode === 200) {
      toast.success("Successfully reversed.");
      client.refetchQueries({
        include: ["bioLcGetMandateCertificateAPI"],
      });
    } else {
      toast.error("An error occurred. Please try again later.");
    }

    setModal("");
    setShowModal(false);
    setIsLoading(false);
  };

  return (
    <Modal
      size="sm"
      isOpen={showModal}
      className="modal-dialog-centered [&>div]:w-[330px]"
    >
      <ModalBody className="flex flex-col items-center w-[330px]">
        <p className="mb-[2px]">{headerText}</p>
        <p className="text-[13px] text-center text-gray-800 mb-0">
          {bodyTextArr.map((text, index) => (
            <span key={index}>
              {text}
              {index < bodyTextArr.length - 1 && (
                <>
                  <br />
                  <br />
                </>
              )}
            </span>
          ))}
        </p>
      </ModalBody>

      <ModalFooter className="p-0 d-block">
        <div className="row g-0 m-0 modal-footer-row">
          <div className="col-6 d-grid">
            <Button
              color="darker-tertiary"
              className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
          <div className="col-6 d-grid">
            <Button
              color="darker-tertiary"
              className="border-0 rounded-0 py-4 bg-transparent text-default"
              onClick={() => {
                onSubmit();
              }}
              disabled={isLoading}
            >
              {isLoading && <Spinner size="sm" className="btn-icon-prefix" />}
              {actionButtonText}
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

MandateReversalModal.propTypes = {
  modal: PropTypes.string,
  setModal: PropTypes.func,
  rowData: PropTypes.object,
  divisionCode: PropTypes.string,
  currentFilterValues: PropTypes.object,
};

export default MandateReversalModal;
