import {Kebab24} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import {useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import {DIV_CODE_GF} from "constants/divisionDetails";
import {actionConfig, linkAction} from "../utils";
import ModalToShow from "./ModalToShow";

import {actionItems, status} from "../constants";

const ActionDropdown = ({
  row,
  table,
  navigateToAllocation,
  currentFilterValues,
  periodStatus,
}) => {
  const [actionModalVisible, setActionModalVisible] = useState("");
  const {division} = useParams();
  const divisionCode = division.toUpperCase();

  const rowStatus = row?.original.outboundRecords?.status;
  const fromMbLocationGroupId =
    row?.original.inboundRecord?.fromMbLocationGroupId ?? null;
  const recordStatus = row?.original.inboundRecord?.recordStatus;

  let finalRowStatus = rowStatus;

  const isTransfer = useMemo(() => {
    return rowStatus === status.AVAILABLE && fromMbLocationGroupId !== null;
  }, [fromMbLocationGroupId, rowStatus]);

  const isDocumentGenerated = useMemo(() => {
    return (
      row?.original?.outboundRecords?.document !== null &&
      divisionCode !== DIV_CODE_GF
    );
  }, [divisionCode, row?.original?.outboundRecords?.document]);

  const isMbClosed = useMemo(() => {
    return periodStatus === "CLOSED" || recordStatus === "CLOSED";
  }, [periodStatus, recordStatus]);

  if (isTransfer) {
    finalRowStatus = status.AVAILABLE_FROM_TRANSFER;
  }

  if (isDocumentGenerated) {
    finalRowStatus =
      rowStatus === status.TRANSFERRED ||
      rowStatus === status.TRANSFERRED_AND_SENT
        ? status.TRANSFERRED_AND_SENT
        : status.ALLOCATED_AND_SENT;
  }

  if (isMbClosed) {
    finalRowStatus = status.CARRIED_OVER;
  }

  const navigate = useNavigate();

  const ACTIONS = useMemo(
    () =>
      Object.values(actionItems).map((action) => ({
        name: action,
        isVisible:
          actionConfig[finalRowStatus + divisionCode]?.visible.includes(action),
        isDisabled:
          !actionConfig[finalRowStatus + divisionCode]?.enabled.includes(
            action,
          ),
        action: () =>
          linkAction(
            divisionCode,
            setActionModalVisible,
            action,
            row,
            table,
            navigate,
            navigateToAllocation,
          ),
      })),
    [finalRowStatus, divisionCode, row, table, navigate, navigateToAllocation],
  );

  return (
    <>
      <UncontrolledDropdown direction="start">
        <DropdownToggle
          color="standard-quartenary"
          className="!px-0"
          caret
          onClick={() => setActionModalVisible("")}
        >
          <Kebab24 />
        </DropdownToggle>
        <DropdownMenu container="body">
          {ACTIONS.filter((action) => action.isVisible).map((action) => (
            <DropdownItem
              key={action.name}
              onClick={action.action}
              disabled={action.isDisabled}
              data-test={`data-test-mb-table-action-${action.name}`}
            >
              {action.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      {actionModalVisible && (
        <ModalToShow
          modal={actionModalVisible}
          setModal={setActionModalVisible}
          rowData={row}
          divisionCode={divisionCode}
          currentFilterValues={currentFilterValues}
        />
      )}
    </>
  );
};

ActionDropdown.propTypes = {
  row: PropTypes.object,
  table: PropTypes.object,
  navigateToAllocation: PropTypes.func,
  currentFilterValues: PropTypes.object,
  periodStatus: PropTypes.string,
};

export default ActionDropdown;
