import * as XLSX from "xlsx";
import {Button} from "reactstrap";
import PropTypes from "prop-types";
import {DownloadDocumentGeneric24} from "@bphxd/ds-core-react/lib/icons";
import {BUTTONS} from "modules/co-processing/constants/coProcessing";

const excelHeaderMap = {
  // Batches
  batch_id: "Batch ID",
  batch_start_time: "#3DHDS Start time",
  batch_end_time: "#3DHDS end time",
  tank_certified_datez: "Tank certified date/time",
  c14_test_date: "C14 test result date",
  operating_condition: "Operating condition",
  tank_number: "Tank number",
  tank_volume: "Tank volume at certification",
  rinPerGal: "RIN/gal",
  c14_pmc: "Percent modern carbon (pMC)",
  submitted: "Submitted",
  renewable_feed_bbl: "#3DHDS Feed - Renewable feed (bbl)",
  cold_recirc_in_renewable_feed_bbl:
    "#3DHDS Feed - Cold recirc in renewable feed (bbl)",
  renewable_feed_tank_col_su_sd_bbl:
    "#3DHDS Feed - Renewable feed tank vol adj to cold recirc in renewable feed for SU/SD (bbl)",
  total_feed_bbl: "#3DHDS Feed - Total feed (bbl)",
  unit_rundown_bbl: "#3DHDS Production - Unit rundown (bbl)",
  hot_diesel_recirc_bbl: "#3DHDS Production - Hot diesel recirc (bbl)",
  cold_recirc_bbl: "#3DHDS Production - Cold recirc (bbl)",
  renewable_feed_lb: "#3DHDS Feed - Renewable feed (lb)",
  cold_recirc_in_renewable_feed_lb:
    "#3DHDS Feed - Cold recirc in renewable feed (lb)",
  renewable_feed_tank_col_su_sd_lb:
    "#3DHDS Feed - Renewable feed tank vol adj to cold recirc in renewable feed for SU/SD (lb)",
  total_feed_lb: "#3DHDS Feed - Total feed (lb)",
  unit_rundown_lb: "#3DHDS Production - Unit rundown (lb)",
  hot_diesel_recirc_lb: "#3DHDS Production - Hot diesel recirc (lb)",
  cold_recirc_lb: "#3DHDS Production - Cold recirc (lb)",
  rundown_density_lb_kgal: "#3DHDS - Rundown density (lb/kgal)",

  // Shipments fields
  status: "Status",
  shipment_date: "Shipment date",
  shipment_type: "Shipment type",
  shipment_id: "Shipment ID",
  actual_volume: "Total actual shipment volume (bbl)",
  credits_qualified: "RINS qualified",
  load_start_time: "Load start time",
  load_end_time: "Load end time",
  renewable_shipment_flag: "Renewable",
  destination: "Destination",
  sub_type: "Sub type",
  notes: "Notes",
};

const columnOrder = {
  batches: [
    "batch_id",
    "batch_start_time",
    "batch_end_time",
    "tank_certified_datez",
    "c14_test_date",
    "operating_condition",
    "tank_number",
    "tank_volume",
    "rinPerGal",
    "c14_pmc",
    "submitted",
    "renewable_feed_bbl",
    "cold_recirc_in_renewable_feed_bbl",
    "renewable_feed_tank_col_su_sd_bbl",
    "total_feed_bbl",
    "unit_rundown_bbl",
    "hot_diesel_recirc_bbl",
    "cold_recirc_bbl",
    "renewable_feed_lb",
    "cold_recirc_in_renewable_feed_lb",
    "renewable_feed_tank_col_su_sd_lb",
    "total_feed_lb",
    "unit_rundown_lb",
    "hot_diesel_recirc_lb",
    "cold_recirc_lb",
    "rundown_density_lb_kgal",
    "batch_created_status",
    "shipment_linked_status",
    "c14_linked_status",
    "feedstock_allocated_status",
    "emts_submitted_status",
    "state_submitted_status",
    "state_final_uploaded_status",
    "completion_percentage",
  ],

  shipments: [
    "batch_id",
    "shipment_date",
    "shipment_type",
    "shipment_id",
    "actual_volume",
    "credits_qualified",
    "load_start_time",
    "load_end_time",
    "notes",
    "destination",
    "sub_type",
    "renewable_shipment_flag",
    "status",
  ],
};

function organizeAndFormatDataFields(item, keyOrder) {
  const orderedFieldsArray = keyOrder.map((key) => {
    if (key === "c14_pmc" && item[key] !== "N/A") {
      const c14_pmc = parseFloat(item[key]) / 100;
      return (c14_pmc * 100).toFixed(2);
    }
    return item[key] ?? "N/A";
  });

  return orderedFieldsArray;
}

export const exportExcelData = (historicalData, historicalType) => {
  // Format data using the provided headerMapping
  const formattedData = historicalData.map((data) =>
    organizeAndFormatDataFields(data, columnOrder[historicalType]),
  );

  const excelHeaders = columnOrder[historicalType].map(
    (key) => excelHeaderMap[key] || key,
  );
  const ws = XLSX.utils.aoa_to_sheet([excelHeaders, ...formattedData]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Historical Data");
  const fileName = `historical_${historicalType}_export.xlsx`;
  XLSX.writeFile(wb, fileName);
};

const ExportHistorical = ({historicalData, historicalType}) => {
  return (
    <Button
      className="rounded-0 mr-5"
      color="primary"
      data-test="historical-export-btn"
      disabled={!historicalData || historicalData?.length === 0}
      onClick={() => exportExcelData(historicalData, historicalType)}
    >
      {BUTTONS.EXPORT}
      <DownloadDocumentGeneric24 className="btn-icon-suffix" />
    </Button>
  );
};

ExportHistorical.propTypes = {
  historicalData: PropTypes.array,
  historicalType: PropTypes.oneOf(["batches", "shipments"]),
};

export default ExportHistorical;
