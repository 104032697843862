import Layout from "modules/common/Layout";
import React from "react";
import {createRoot} from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";

import {ErrorBoundary} from "react-error-boundary";
import {ToastContainer} from "react-toastify";
import reportWebVitals from "utils/helpers/reportWebVitals";

import CommonErrorPage from "modules/common/ErrorPages/CommonErrorPage";
import Footer from "modules/common/FooterNew";
import UserFeedbackButton from "modules/userFeedback/UserFeedbackButton";
import {Apollo} from "providers/Apollo";
import GlobalNotification from "providers/GlobalNotification/GlobalNotification";
import {Msal} from "providers/Msal";
import {AppSetting} from "providers/appSetting";
import {history} from "providers/routerHistory";
import {UserSettings} from "providers/userSettings";
import {unstable_HistoryRouter as HistoryRouter} from "react-router-dom";
import App from "./App";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <HistoryRouter history={history}>
      <Msal>
        <Apollo>
          <AppSetting>
            <UserSettings>
              <GlobalNotification>
                <Layout>
                  <ErrorBoundary
                    FallbackComponent={CommonErrorPage}
                    onReset={() => {
                      // reset the state of your app so the error doesn't happen again
                    }}
                  >
                    <ToastContainer
                      data-test="toast-container"
                      position="top-center"
                      autoClose={3000}
                      hideProgressBar
                      draggable={false}
                      limit={1}
                    />
                    <div className="flex-1 min-h-screen min-w-0 mb-5">
                      <App />
                    </div>

                    <UserFeedbackButton />

                    <Footer />
                  </ErrorBoundary>
                </Layout>
              </GlobalNotification>
            </UserSettings>
          </AppSetting>
        </Apollo>
      </Msal>
    </HistoryRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
