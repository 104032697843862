import {useLazyQuery, useQuery} from "@apollo/client";
import {
  COPRO_US_SHIPMENTS_API_GET_BATCHES,
  COPRO_US_SHIPMENTS_API_GET_SHIPMENTS,
} from "graphql/coprocessing/shipments";
import ShipmentColumn from "modules/co-processing/components/Shipments/ShipmentColumn";
import {
  BUTTONS,
  ITEMS_PER_PAGE,
  TITLES,
} from "modules/co-processing/constants/shipments";
import {useCallback, useEffect, useRef, useState} from "react";
import tw from "twin.macro";

const ColumnWrapper = tw.div`w-1/2 px-2`;
const getShipmentTypeFilter = (shipmentType) =>
  shipmentType ? [{key: "shipment_type", values: [shipmentType]}] : [];

const ShipmentView = () => {
  const [activeBatchesData, setActiveBatches] = useState([]);
  const [shipmentsData, setShipmentsData] = useState([]);
  const [shipmentDateRange, setShipmentDateRange] = useState([]);
  const [shipmentType, setShipmentType] = useState("");
  const [tankCertifiedDateRange, setTankCertifiedDateRange] = useState([]);
  const [batchesOffset, setBatchesOffset] = useState(0);
  const [shipmentsOffset, setShipmentsOffset] = useState(0);
  const [mounted, setMounted] = useState(false);
  const [showMoreBatchesButton, setMoreBatchesButton] = useState(true);
  const [showMoreShipmentsButton, setMoreShipmentsButton] = useState(true);

  const lastSelectedCardRef = useRef({shipment_id: null, batch_id: null});
  const handleCardSelect = (id, type) => {
    if (!type) return;

    const key = `${type}_id`;
    lastSelectedCardRef.current[key] = id;
  };

  const {loading: batchesLoading} = useQuery(
    COPRO_US_SHIPMENTS_API_GET_BATCHES,
    {
      variables: {offset: 0, tankCertifiedDateRange},
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        const batchesList = data?.bioLcCoproUsShipmentsApi?.body?.batches || [];
        setActiveBatches(batchesList);
        setMoreBatchesButton(batchesList.length > 0);
        setBatchesOffset(1); // Increment offset by 1 after initial fetch
      },
    },
  );

  const handleTankCertifiedDateRange = useCallback(
    (selectedDates) => {
      if (selectedDates?.length === 2) {
        const endDate = new Date(selectedDates[1]); // Make end date inclusive
        endDate.setDate(selectedDates[1].getDate() + 1);
        const dates = [selectedDates[0], endDate];

        setActiveBatches([]);
        setBatchesOffset(0); // Reset offset to 0 when date range changes
        setTankCertifiedDateRange(dates.map((date) => date.toISOString()));
      }
    },
    [setTankCertifiedDateRange, setBatchesOffset],
  );

  const [fetchBatches, {data: batchesDataRes, loading: moreBatchesLoading}] =
    useLazyQuery(COPRO_US_SHIPMENTS_API_GET_BATCHES, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        const dataArray =
          batchesDataRes?.bioLcCoproUsShipmentsApi?.body?.batches;
        if (!Array.isArray(dataArray) || dataArray.length === 0) {
          setMoreBatchesButton(false);
          return;
        }
        setMoreBatchesButton(true);
        setActiveBatches((prevBatches) => [...prevBatches, ...dataArray]);
        setBatchesOffset((prevOffset) => prevOffset + 1);
      },
    });

  const [
    fetchMoreShipments,
    {data: shipmentsDataRes, loading: moreShipmentsLoading},
  ] = useLazyQuery(COPRO_US_SHIPMENTS_API_GET_SHIPMENTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      const dataArray =
        shipmentsDataRes?.bioLcCoproUsShipmentsApi?.body?.shipments;
      if (!Array.isArray(dataArray) || dataArray.length === 0) {
        setMoreShipmentsButton(false);
        return;
      }
      setMoreShipmentsButton(true);
      setShipmentsData((prevShipments) => [...prevShipments, ...dataArray]);
      setShipmentsOffset((prevOffset) => prevOffset + 1);
    },
  });

  const [
    fetchFilteredShipments,
    {data: shipmentsDataFiltered, loading: shipmentsLoading},
  ] = useLazyQuery(COPRO_US_SHIPMENTS_API_GET_SHIPMENTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      const dataArray =
        shipmentsDataFiltered?.bioLcCoproUsShipmentsApi?.body?.shipments;
      setShipmentsData([...dataArray]);
      if (!Array.isArray(dataArray) || dataArray.length === 0) {
        setMoreShipmentsButton(false);
        return;
      }
      setMoreShipmentsButton(true);
      setShipmentsOffset(1);
    },
  });

  const refreshData = useCallback(() => {
    const currNumberOfShipments = shipmentsData.length;
    const pageSize = Math.ceil(currNumberOfShipments / ITEMS_PER_PAGE) * 10; // Round currNumberOfShipments to nearest multiple of 10

    setShipmentsData([]);
    setShipmentsOffset(0);
    fetchFilteredShipments({
      variables: {
        offset: 0,
        dateRange: shipmentDateRange,
        filtersList: getShipmentTypeFilter(shipmentType),
        ...(pageSize > ITEMS_PER_PAGE && {pageSize}),
      },
    });
    setActiveBatches([]);
    setBatchesOffset(0);
    fetchBatches({
      variables: {
        offset: 0,
        tankCertifiedDateRange,
        ...(activeBatchesData?.length && {pageSize: activeBatchesData.length}),
      },
    });
  }, [
    fetchFilteredShipments,
    shipmentDateRange,
    shipmentsData,
    shipmentType,
    fetchBatches,
    tankCertifiedDateRange,
    activeBatchesData,
  ]);

  const loadMoreBatches = useCallback(() => {
    fetchBatches({
      variables: {
        offset: batchesOffset * ITEMS_PER_PAGE,
        tankCertifiedDateRange,
      },
    });
  }, [fetchBatches, batchesOffset, tankCertifiedDateRange]);

  const loadMoreShipments = useCallback(() => {
    fetchMoreShipments({
      variables: {
        offset: shipmentsOffset * ITEMS_PER_PAGE,
        dateRange: shipmentDateRange,
        filtersList: getShipmentTypeFilter(shipmentType),
      },
    });
  }, [fetchMoreShipments, shipmentsOffset, shipmentDateRange, shipmentType]);

  const filterDateRangeShipments = useCallback(
    (dateRange) => {
      setShipmentDateRange(dateRange);
      setShipmentsData([]);
      fetchFilteredShipments({
        variables: {
          offset: 0,
          dateRange,
          filtersList: getShipmentTypeFilter(shipmentType),
        },
      });
    },
    [shipmentType, fetchFilteredShipments, setShipmentDateRange],
  );

  const filterTypeShipments = useCallback(
    (shipmentType) => {
      setShipmentType(shipmentType);
      setShipmentsData([]);
      fetchFilteredShipments({
        variables: {
          offset: 0,
          dateRange: shipmentDateRange,
          filtersList: getShipmentTypeFilter(shipmentType),
        },
      });
    },
    [
      shipmentDateRange,
      setShipmentsData,
      setShipmentType,
      fetchFilteredShipments,
    ],
  );

  const refetchShipments = useCallback(
    () =>
      fetchFilteredShipments({
        variables: {
          offset: 0,
          dateRange: shipmentDateRange,
          filtersList: shipmentType
            ? [{key: "shipment_type", values: [shipmentType]}]
            : [],
        },
      }),
    [fetchFilteredShipments, shipmentDateRange, shipmentType],
  );

  useEffect(() => {
    if (mounted) return;
    loadMoreShipments();
    setMounted(true);
  }, [loadMoreShipments, mounted, setMounted]);

  const isBatchesLoading = batchesLoading || moreBatchesLoading;
  const isShipmentsLoading = shipmentsLoading || moreShipmentsLoading;

  return (
    <div className="flex gap-4">
      <ColumnWrapper>
        <ShipmentColumn
          title={TITLES.ACTIVE_BATCHES}
          data={activeBatchesData}
          refreshData={refreshData}
          isLoading={isBatchesLoading}
          buttonText={BUTTONS.LOAD_MORE_BATCHES}
          loadMore={loadMoreBatches}
          showButton={showMoreBatchesButton}
          handleTankCertifiedDates={handleTankCertifiedDateRange}
          lastSelectedCardRef={lastSelectedCardRef}
          onCardSelect={(id) => handleCardSelect(id, "batch")}
        />
      </ColumnWrapper>
      <ColumnWrapper>
        <ShipmentColumn
          title={TITLES.SHIPMENTS}
          data={shipmentsData}
          refreshData={refreshData}
          isLoading={isShipmentsLoading}
          buttonText={BUTTONS.LOAD_MORE_SHIPMENTS}
          loadMore={loadMoreShipments}
          showButton={showMoreShipmentsButton}
          refetchShipments={refetchShipments}
          filterDateRangeShipments={filterDateRangeShipments}
          filterTypeShipments={filterTypeShipments}
          lastSelectedCardRef={lastSelectedCardRef}
          onCardSelect={(id) => handleCardSelect(id, "shipment")}
        />
      </ColumnWrapper>
    </div>
  );
};

export default ShipmentView;
