import {gql} from "@apollo/client";

export const GET_COMPLIANCE_SCHEMES = gql`
  query GetComplianceSchemes($ruleVersionId: String!) {
    bioLcComplianceRuleEngineGetComplianceSchemes(
      event: {ruleVersionId: $ruleVersionId}
    ) {
      complianceSchemes {
        uuid
        complianceScheme
      }
    }
  }
`;

export default GET_COMPLIANCE_SCHEMES;
