import {
  CalculatorDetailed48,
  Edit48,
  Electric48,
  SpreadsheetGeneric48,
  Swap48,
} from "@bphxd/ds-core-react/lib/icons";
import Labels from "./Labels";

export const blockList = [
  {
    id: "select_data",
    ruleBlockMasterId: "selectDataBlockRuleId",
    blockName: Labels.SELECT_DATA,
    blockType: "data",
    disabled: true,
    configuration: {
      table_name: undefined,
      predicate: undefined,
      description: undefined,
    },
  },
  {
    id: "write_data",
    ruleBlockMasterId: "writeDataBlockRuleId",
    blockName: Labels.WRITE_DATA,
    blockType: "data",
    disabled: true,
    configuration: {
      table_name: undefined,
      result_column: undefined,
      description: undefined,
    },
  },
  {
    id: "convert_units",
    ruleBlockMasterId: "convertUnitsBlockRuleId",
    blockName: Labels.CONVERT_UNITS,
    blockType: "conversion",
    disabled: false,
    configuration: {
      input_column: undefined,
      conversion_factor: undefined,
      conversion_factor_name: undefined,
      output_column: undefined,
      description: undefined,
    },
  },

  {
    id: "energy_density",
    ruleBlockMasterId: "energyDensityBlockRuleId",
    blockName: Labels.ENERGY_DENSITY,
    blockType: "complianceRegulation",
    disabled: false,
    configuration: {
      input_column: undefined,
      prod_hrc_column: undefined,
      output_column: undefined,
      description: undefined,
    },
  },

  {
    id: "calculate",
    ruleBlockMasterId: "calculateBlockRuleId",
    blockName: Labels.CALCULATE,
    blockType: "calculation",
    disabled: false,
    configuration: {
      formula: undefined,
      output_column: undefined,
      description: undefined,
    },
  },
];

export const blockIconList = {
  select_data: <SpreadsheetGeneric48 className="btn-icon-prefix" />,
  write_data: <Edit48 className="btn-icon-prefix" />,
  convert_units: <Swap48 className="btn-icon-prefix" />,
  energy_density: <Electric48 className="btn-icon-prefix" />,
  calculate: <CalculatorDetailed48 className="btn-icon-prefix" />,
};

export const blockTypeList = {
  calculation: Labels.CALCULATION,
  conversion: Labels.CONVERSION,
  data: Labels.DATA,
  complianceRegulation: Labels.COMPLIANCE_REGULATIONS,
};

export default blockList;
